import { Component, OnInit } from '@angular/core';

import { GeneralMenuComponent } from 'app/modules/shared/general-menu/general-menu.component';

import { UserService } from 'app/services/user.service';

interface Route {
	text: string
	icon?: string
	iconRoute?: string
	route: string
	disabled: boolean
}

@Component({
	selector: 'app-school-layout',
	standalone: true,
	imports: [ GeneralMenuComponent ],
	templateUrl: './school-layout.component.html',
	styleUrl: './school-layout.component.scss'
})
export class SchoolLayoutComponent implements OnInit {

	breakPoint: number = 4;

	routes: Route[] = [
		{text: 'Tablero', icon: 'chart', route: 'school/dashboard', disabled: false},
		{text: 'Colaboradores', icon: 'travel-front-color', route: 'school/collaborator', disabled: false},
		{text: 'Aulas', icon: 'sheet-start', route: 'school/classroom', disabled: false},
		{text: 'Tareas', icon: 'pencil', route: 'school/homework', disabled: false},
		{text: 'Go Talks', iconRoute: 'assets/images/logo/logo-negative-rectangle.png', route: 'practice', disabled: false},
	]

	constructor(
		public userService: UserService
	) {}

	ngOnInit(): void {
		if (this.userService.user.isTeacher()) {
			this.routes = [
				{text: 'Tablero', icon: 'chart', route: 'school/dashboard', disabled: false},
				{text: 'Aulas', icon: 'sheet-start', route: 'school/classroom', disabled: false},
				{text: 'Tareas', icon: 'pencil', route: 'school/homework', disabled: false},
				{text: 'Go Talks', iconRoute: 'assets/images/logo/logo-negative-rectangle.png', route: 'practice', disabled: false},
			]

			this.breakPoint = 3;
		}
	}
}
