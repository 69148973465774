<div *ngIf="user&&ddss.isGreaterEqual('sm')" class="flex flex-row w-full items-center justify-between mb-12 px-6 relative cursor-pointer" [matMenuTriggerFor]="menu">
    <div class="flex flex-row xl:w-full gap-4 items-center mx-auto xl:mx-0">
        <!--<img *ngIf="user?.google_register&&imageUrl"
            class="rounded-full h-8 w-8"
            [src]="imageUrl"
            alt="">-->
        <img *ngIf="user?.google_avatar"
            class="rounded-full h-8 w-8"
            [src]="user.google_avatar"
            alt="">
        <img *ngIf="!user?.google_avatar"
            class="rounded-full h-8 w-8"
            [src]="'assets/images/misc/avatar.webp'"
            alt="avatar">
        <div class="hidden xl:block w-full">
            <div class="w-full flex flex-col gap-1">
                <div class="text-[#767676] text-sm truncate xl:w-32 2xl:w-40">{{user.email}}</div>
                <div class="text-white text-xs">{{user.name}}</div>
            </div>
        </div>
    </div>
    <div class="hidden xl:block">
        <mat-icon class="size-6 text-[#757575] rotate-90"
            [svgIcon]="'chevron_right'"></mat-icon>
    </div>
</div>

<mat-menu #menu="matMenu" yPosition="above" xPosition="before">
    <div class="flex xl:w-60 2xl:w-64 w-full text-white">
        <div class="flex p-4 w-full items-center rounded-xl hover:bg-[#1F50A0] space-x-4 cursor-pointer" (click)="logout()">
            <mat-icon [svgIcon]="'logout'"></mat-icon>
            <div>Logout</div>
        </div>
    </div>
</mat-menu>


<div *ngIf="user&&ddss.is.xs" [matMenuTriggerFor]="menuMobile">
    <div class="flex flex-row xl:w-full gap-4 items-center mx-auto xl:mx-0">
        <img *ngIf="user?.google_register"
            class="rounded-full h-10 w-10"
            [src]="user?.google_avatar"
            alt="">
        <img *ngIf="!user?.google_register"
            class="rounded-full h-10 w-10"
            [src]="'assets/images/misc/avatar.webp'"
            alt="avatar">
    </div>
</div>

<mat-menu #menuMobile="matMenu" yPosition="below" xPosition="after">
    <div class="flex min-w-32 w-full text-white">
        <div class="flex p-4 w-full items-center rounded-xl hover:bg-[#1F50A0] space-x-4 cursor-pointer" (click)="logout()">
            <mat-icon [svgIcon]="'logout'"></mat-icon>
            <div>Logout</div>
        </div>
    </div>
</mat-menu>