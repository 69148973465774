import { Component, Input } from '@angular/core';
import { NgIf } from '@angular/common';

@Component({
	selector: 'circle-loader',
	standalone: true,
	imports: [ NgIf ],
	templateUrl: './circle-loader.component.html',
	styleUrl: './circle-loader.component.scss'
})
export class CircleLoaderComponent {
	@Input() size: number = 6
}
