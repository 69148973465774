<div class="min-h-screen h-screen max-h-screen flex flex-col w-full flex-grow bg-background overflow-hidden relative">

    <back-button class="top-3 left-4 lg:top-2 lg:left-2 xl:top-5 xl:left-5 absolute z-50"
        [style]="'close'"></back-button>

    <div
        class="grow h-full flex flex-col w-full justify-center lg:max-w-4xl xl:max-w-5xl m-0 md:mt-8 lg:mt-4 xl:mt-14 mx-auto overflow-y-hidden">

        <ng-container *ngIf="ddss.is.xs">
            <div class="w-full p-4 pl-12 flex flex-row justify-between border-b-2 border-[#1F50A0]">
                <div class="flex justify-center items-center w-full">
                    <img class="w-20"
                        src="assets/images/logo/logo-negative-rectangle.png"
                        alt="Go Talks logo">
                </div>
                <div class="flex bg-[#1F50A0] rounded-full p-2 sm:p-1 items-center justify-center cursor-pointer">
                    <mat-icon [svgIcon]="'notifications'"
                        class="flex items-center justify-center text-white text-[1rem]"></mat-icon>
                </div>
            </div>
        </ng-container>

        <!-- aqui -->
        <progress-bar class="w-full z-20 p-4 pt-8 sm:p-0"
            [color]="'blue'"
            [progress]="percentage"
            [height]="12"></progress-bar>

        <div class="w-full flex grow h-full flex-col mx-auto z-30 overflow-hidden"
            [ngClass]="[biggerWidth ? 'max-w-none p-4 sm:p-0 lg:pt-4 xl:pt-8' : 'max-w-md p-8 sm:pt-8 lg:pt-4 xl:pt-16']">
            <router-outlet></router-outlet>
        </div>
    </div>

    <div class="flex w-full border-t-2 border-[#1F50A0] p-8 sm:p-0 h-auto sm:h-36 lg:h-[7rem] xl:h-36 z-20">
        <div class="flex flex-row w-full h-full items-center lg:max-w-4xl xl:max-w-5xl mx-auto gap-4"
            [ngClass]="{
            'justify-between': allowedBack,
            'justify-end': !allowedBack
        }">
            <!-- Back -->
            <div *ngIf="!ddss.is.xs && allowedBack"
                (click)="clickBack()"
                class="flex items-center justify-center w-full sm:w-auto rounded-xl p-4 px-16 border bg-background border-gt-border-button text-white cursor-pointer button-hover">
                Atrás
            </div>

            <back-button *ngIf="ddss.is.xs && allowedBack"
                (click)="clickBack()"
                action="emit-click"></back-button>

            <standard-button (onClick)="click()"
                [allowLoading]="lastStep"
                [allowed]="allowedContinue"
                class="w-full sm:w-auto"
                extendedClass="w-full sm:w-48"
                [text]="!wizardEnded && !lastStep ? 'Continuar' : 'Finalizar'"></standard-button>
        </div>
    </div>

    <background-grid [ballPosition]="'left'"
        class="w-full h-full absolute z-10 opacity-70"></background-grid>
    <screen-size></screen-size>
</div>