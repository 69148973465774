import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';

import { AvatarService } from 'app/services/avatar.service';
import { SpeechTextProcessorService } from 'app/services/speech-text-processor.service';
import { TextTimingService } from './text-timing.service';

import { Avatar, AvatarVariant, AvatarVoice } from 'app/types/avatar/avatar.type';
import { WhisperService } from './whisper.service';
import { audioStaked } from 'app/types/recorder/recorder.type';
import { WhisperWord } from 'app/types/wishper/wishper.type';

export interface WordProgress {
	index: number;
	word: string;
	ids: string[];  // Nuevo campo agregado
  }

interface SpeechMessage {
	text: string;
	ids: string[];
	avatar?: Avatar;
	audio?: HTMLAudioElement;
	granularities?: boolean;
	whisperResponse?: WhisperWord[];
	timeouts?: number[];
	startTime?: number;
	language?: 'en-IN' | 'en-US' | 'en-GB' | 'en-AU' | 'es-US' | 'es-ES';
}

interface VoiceConfig {
	languageCode: string;
	name: string;
	pitch?: number;
	speakingRate?: number;
}

@Injectable({
  providedIn: 'root'
})
export class SpeechService {
	private readonly apiUrl = 'https://texttospeech.googleapis.com/v1/text:synthesize';
	private readonly apiKey = 'AIzaSyBsjCJG_wTtm-ETmy9_H1CMr5V_wrX1bL4';

	public emoticonRegex: RegExp = /[\u{1F000}-\u{1FFFF}\u{2600}-\u{27BF}\u{2B50}\u{2934}-\u{2935}\u{2B05}-\u{2B07}\u{2934}-\u{2935}\u{3297}\u{3299}\u{303D}\u{00A9}\u{00AE}\u{2122}\u{23F0}\u{23F3}]/gu;

	private readonly defaultVoiceConfig: VoiceConfig = {
		languageCode: 'en-GB',
		name: 'en-GB-Wavenet-A',
		pitch: 6,
		speakingRate: 1
	};

	private messageQueue: SpeechMessage[] = [];
	private isProcessing = false;
	private currentAudio?: HTMLAudioElement;

	// Subject para el progreso de palabras
	public readonly wordProgress$ = new Subject<WordProgress>();

	constructor(
		private http: HttpClient,
		private avatarService: AvatarService,
		private speechTextProcessorService: SpeechTextProcessorService,
		private textTimingService: TextTimingService
	) {}

	speakSpanish(text: string, type: 'woman' | 'man' = 'woman'): void {

		let spanishVoice = new AvatarVoice();
		spanishVoice.languageCode = 'es-ES';
		spanishVoice.name = type == 'woman' ? 'es-ES-Journey-F' : 'es-ES-Journey-D';
		

		let avatar: Avatar = new Avatar();
		avatar.es.push(spanishVoice);

		const message: SpeechMessage = {
			text,
			ids: [],
			avatar,
			granularities: false,
			timeouts: [],
			language: 'es-ES'
		};

		// Informar que está cargando
		this.avatarService.speakingState$.next({
			ids: message.ids,
			isSpeaking: false,
			isLoading: true
		});

		this.messageQueue.push(message);

		if (!this.isProcessing) {
			this.processNextMessage();
		}
	}

	speak(text: string, ids: string | string[], avatar?: Avatar, granularities: boolean = false): void {
		const message: SpeechMessage = {
			text,
			ids: Array.isArray(ids) ? ids : [ids],
			avatar,
			granularities,
			timeouts: []
		};
	
		// Informar que está cargando
		this.avatarService.speakingState$.next({
			ids: message.ids,
			isSpeaking: false,
			isLoading: true
		});

		this.messageQueue.push(message);

		if (!this.isProcessing) {
		this.processNextMessage();
		}
	}

	stop(): void {
		const currentMessage = this.messageQueue[0];
		const currentIds = currentMessage?.ids || [];

		if (this.currentAudio) {
			this.currentAudio.pause();
			this.currentAudio = undefined;
		}

		// Limpiar todos los timeouts pendientes
		if (currentMessage?.timeouts) {
			currentMessage.timeouts.forEach(timeout => clearTimeout(timeout));
			currentMessage.timeouts = [];
		}

		this.messageQueue = [];
		this.isProcessing = false;
		this.avatarService.speakingState$.next({ ids: currentIds, isSpeaking: false, isLoading: false });
	}

	private async processNextMessage(): Promise<void> {
		if (this.messageQueue.length === 0) {
			this.isProcessing = false;
			return;
		}

		this.isProcessing = true;
		const message = this.messageQueue[0];
		
		try {
			const voiceConfig = message.avatar ? 
				this.getAvatarVoiceConfig(message.avatar, message.language) : 
				this.defaultVoiceConfig;

			const { audio, blob } = await this.generateAudio(message.text, voiceConfig);
			message.audio = audio;
			
			if (message.granularities) {
				try {
					const timings = this.textTimingService.calculateWordTimings( this.cleanMessage(message.text), audio.duration);
					
					message.whisperResponse = timings.map(timing => ({
						word: timing.word,
						start: timing.start,
						end: timing.end
					}));
				
					this.avatarService.speakingState$.next({ 
						ids: message.ids, 
						isSpeaking: true,
						isLoading: false,  // Quitamos el estado de carga
						whisperData: message.whisperResponse 
					});
				
					this.setupWordTimeouts(message);
				} catch (error) {
					console.error('Error calculating word timings:', error);
					this.avatarService.speakingState$.next({ ids: message.ids, isSpeaking: true });
				}
			  } else {
				this.avatarService.speakingState$.next({ ids: message.ids, isSpeaking: true, isLoading: false });
			}
			
			await this.playAudio(audio);
			
			this.avatarService.speakingState$.next({ 
				ids: message.ids, 
				isSpeaking: false,
				whisperData: message.whisperResponse 
			});

			// Limpiar timeouts al finalizar
			if (message.timeouts) {
				message.timeouts.forEach(timeout => clearTimeout(timeout));
				message.timeouts = [];
			}
			
			this.messageQueue.shift();
			this.processNextMessage();
			
		} catch (error) {
			console.error('Error processing speech message:', error);
			if (message.timeouts) {
				message.timeouts.forEach(timeout => clearTimeout(timeout));
				message.timeouts = [];
			}
			this.messageQueue.shift();
			this.processNextMessage();
		}
	}

	private setupWordTimeouts(message: SpeechMessage): void {
		if (!message.whisperResponse || !message.timeouts) return;

		message.startTime = Date.now();

		message.whisperResponse.forEach((word, index) => {
			const timeout = window.setTimeout(() => {
				this.wordProgress$.next({
					index,
					word: word.word,
					ids: message.ids
				});
			}, word.start * 1000) as number;

			message.timeouts?.push(timeout);
		});

		// Agregamos el timeout final
		const lastWord = message.whisperResponse[message.whisperResponse.length - 1];
		const finalTimeout = window.setTimeout(() => {
			this.wordProgress$.next({
				index: message.whisperResponse.length,  // índice después de la última palabra
				word: "final",
				ids: message.ids
			});
		}, lastWord.end * 1000) as number;
	
		message.timeouts?.push(finalTimeout);
	}

	private getAvatarVoiceConfig(avatar: Avatar, language: string = null): VoiceConfig {
		switch (language) {
			case "es-ES":
				return {
					languageCode: avatar.es?.[0]?.languageCode || this.defaultVoiceConfig.languageCode,
					name: avatar.es?.[0]?.name || this.defaultVoiceConfig.name
				};
		
			default:

			let voiceConfig: VoiceConfig = {
				languageCode: avatar.en?.[0]?.languageCode || this.defaultVoiceConfig.languageCode,
				name: avatar.en?.[0]?.name || this.defaultVoiceConfig.name,
				pitch: null,
				speakingRate: this.defaultVoiceConfig.speakingRate
			};

			if (!avatar.en?.[0]?.name.includes("Journey")) {
				voiceConfig.pitch = avatar.en?.[0]?.pitch || this.defaultVoiceConfig.pitch;
			}

			return voiceConfig;
		}

		
	}

	private async generateAudio(text: string, config: VoiceConfig): Promise<{ audio: HTMLAudioElement; blob: Blob }> {
		const cleanedText = this.cleanMessage(text);
		const body = {
			audioConfig: {
				audioEncoding: "MP3",
				pitch: config.pitch,
				speakingRate: config.speakingRate
			},
			input: {
				text: cleanedText
			},
			voice: {
				languageCode: config.languageCode,
				name: config.name
			}
		};
		const headers = { 'Content-Type': 'application/json' };
	
		let attempts = 0;
		const maxInitialAttempts = 3;
		
		while (true) {
			try {
				// Intento de llamada a la API
				const response = await this.http.post(
					this.apiUrl + '?key=' + this.apiKey, 
					body, 
					{ headers }
				).toPromise();
	
				// Si llegamos aquí, la llamada fue exitosa
				const blob = this.base64ToBlob(
					(response as any).audioContent, 
					'audio/mp3'
				);
				
				const audio = new Audio();
				audio.src = URL.createObjectURL(blob);
				
				await new Promise((resolve) => {
					audio.addEventListener('loadedmetadata', resolve, { once: true });
				});
				
				return { audio, blob };
	
			} catch (error) {
				attempts++;
				console.error(`Error en intento ${attempts}:`, error);
	
				if (attempts < maxInitialAttempts) {
					// Durante los primeros 3 intentos, reintentamos inmediatamente
					console.log(`Reintentando inmediatamente (intento ${attempts + 1} de ${maxInitialAttempts})...`);
					continue;
				}
	
				// Después de los 3 primeros intentos, esperamos 5 segundos entre cada intento
				console.log('Esperando 5 segundos antes de reintentar...');
				await new Promise(resolve => setTimeout(resolve, 5000));
				console.log('Reintentando después de espera...');
			}
		}
	}

	private playAudio(audio: HTMLAudioElement): Promise<void> {
		return new Promise((resolve, reject) => {

			if (this.currentAudio) {
				this.currentAudio.pause();
			}
			
			this.currentAudio = audio;
			
			audio.onended = () => {
				this.currentAudio = undefined;
				resolve();
			};
			
			audio.onerror = (error) => {
				this.currentAudio = undefined;
				reject(error);
			};

			audio.play().catch(reject);
		});
	}

	private base64ToBlob(base64: string, type: string): Blob {
		const byteCharacters = atob(base64);
		const byteArrays = [];

		for (let offset = 0; offset < byteCharacters.length; offset += 512) {
		const slice = byteCharacters.slice(offset, offset + 512);
		const byteNumbers = new Array(slice.length);
		
		for (let i = 0; i < slice.length; i++) {
			byteNumbers[i] = slice.charCodeAt(i);
		}
		
		byteArrays.push(new Uint8Array(byteNumbers));
		}

		return new Blob(byteArrays, { type });
	}

	private cleanMessage(text: string): string {
		return text.replace(this.emoticonRegex, '');
	}
}