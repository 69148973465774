import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { initializeApp } from "firebase/app";
import { 
	getAuth, 
	Auth, 
	onAuthStateChanged, 
	User, 
	GoogleAuthProvider, 
	signInWithPopup, 
	signOut, 
	UserCredential 
} from "firebase/auth";

// Interfaz para representar los datos del usuario de Google
interface GoogleUser {
	uid: string | null;
	email: string | null;
	displayName: string | null;
	photoURL: string | null;
}

@Injectable({
  providedIn: 'root'
})
export class GoogleAuthService {
	private auth: Auth;
	private readonly clientId = '1442497070-f61nckiupbrmqvpj2lv1neunmhjs2v8h.apps.googleusercontent.com';
	private readonly clientSecret = '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';
	private readonly redirectUri = `${environment.frontUrl}classroom`;
	private readonly scope = [
		'https://www.googleapis.com/auth/classroom.courses.readonly',
		'https://www.googleapis.com/auth/classroom.rosters.readonly',
		'https://www.googleapis.com/auth/classroom.profile.emails'
	].join(' ');

	public userSignedIn = new BehaviorSubject<User | null>(null);
	public userSignedOut = new BehaviorSubject<boolean>(false);
	private accessTokenSubject = new BehaviorSubject<string>('');
	public accessToken$ = this.accessTokenSubject.asObservable();

	constructor(
		private http: HttpClient,
	) {
		this.initializeFirebase();
	}

	private initializeFirebase(): void {
		const app = initializeApp(environment.firebase);
		this.auth = getAuth(app);
		this.setupAuthStateListener();
	}

	private setupAuthStateListener(): void {
		onAuthStateChanged(this.auth, (user) => {
		if (user) {
			this.userSignedIn.next(user);
		} else {
			this.userSignedOut.next(true);
		}
		});
	}

	public requestGoogleAuth(): void {
		const provider = new GoogleAuthProvider();
		signInWithPopup(this.auth, provider).then((result: UserCredential) => {
		// Manejar el resultado de la autenticación si es necesario
		});
	}

	public requestGoogleSignOut(): void {
		signOut(this.auth).then(() => {
		console.log("Usuario desconectado exitosamente");
		}).catch((error) => {
		console.error("Error al desconectar:", error);
		});
	}

	// Métodos relacionados con Google Classroom

	public getAuthorizationUrl(): string {
		return `https://accounts.google.com/o/oauth2/v2/auth?client_id=${this.clientId}&redirect_uri=${this.redirectUri}&response_type=code&scope=${this.scope}`;
	}

	public exchangeCodeForToken(code: string): Observable<any> {
		const params = new HttpParams()
		.set('code', code)
		.set('client_id', this.clientId)
		.set('client_secret', this.clientSecret)
		.set('redirect_uri', this.redirectUri)
		.set('grant_type', 'authorization_code');

		return this.http.post<any>('https://oauth2.googleapis.com/token', params.toString(), {
		headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
		});
	}

	public setAccessToken(token: string): void {
		this.accessTokenSubject.next(token);
	}
}