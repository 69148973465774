import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { NgIf } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { User } from 'app/types/user.type';

import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';

import { AuthService } from 'app/services/auth.service';
import { DeviceDetectorService } from 'app/services/device-detector.service';

@Component({
	selector: 'user-menu',
	standalone: true,
	imports: [NgIf, MatIconModule, MatMenuModule],
	templateUrl: './user-menu.component.html',
	styleUrl: './user-menu.component.scss',
	host: { class: "flex w-full" }
  })
  export class UserMenuComponent implements OnInit {
	@Input() user: User;
	imageUrl: string;
	private retryCount = 0;
	private maxRetries = 3;
	private defaultAvatar = 'assets/default-avatar.png';
  
	constructor(
		private http: HttpClient,
		private router: Router,
		public suthService: AuthService,
		public ddss: DeviceDetectorService
	) {}
  
	ngOnInit(): void {
	}
  
	logout() {
		this.suthService.signOut();
		this.router.navigate(["sign-in"]);
	}
  
	ngOnDestroy() {
		if (this.imageUrl && this.imageUrl !== this.defaultAvatar) {
			URL.revokeObjectURL(this.imageUrl);
		}
	}
}
