import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Location, NgIf, NgClass } from '@angular/common';
import { MatIcon } from '@angular/material/icon';

import { DeviceDetectorService } from 'app/services/device-detector.service';

@Component({
	selector: 'back-button',
	standalone: true,
	imports: [ MatIcon, NgIf, NgClass ],
	templateUrl: './back-button.component.html',
	styleUrl: './back-button.component.scss'
})
export class BackButtonComponent {

	@Input() action: 'go-back' | 'emit-click' = 'go-back'
	@Input() style: 'standard' | 'back-mobile' | 'close' =  'standard'
	@Output() click: EventEmitter<boolean> = new EventEmitter<boolean>()

	constructor(
		private location: Location,
        public dd: DeviceDetectorService
	) { }

	onClick() {		

		if (this.action == 'go-back') {
			this.location.back();
		} else {
			this.click.emit(true);
		}
	}
}
