import { base, levenshteinDistanceWords } from 'app/types/base.type';
import { Lesson } from 'app/types/lesson/lesson.type';
import { Message } from 'app/types/chat/message.type';
import { StatisticsChat, COMMON_WORDS_WHITELIST } from 'app/types/chat/statistics.type';
import { User } from 'app/types/user.type';
import { Avatar } from 'app/types/avatar/avatar.type';
import { RolePlaying } from 'app/types/roleplaying/roleplaying.type';
import { AssessmentResponse } from 'app/types/chat/assessment-response.type';

export class Chat extends base {
    id_user: string;
    lang: string;
    context_variables: ChatVariables;
    messages: Message[];
    questions: string[] = [];
    percentage_of_completion?: number;
    
    total_user_words?: number;
    total_user_speaked?: number;
    total_assistant_words?: number;
    total_assistant_speaked?: number;
    total_messages?: number;
    total_time?: number;
    total_time_formated?: string;
    
    statistics?: StatisticsChat;

    id_class_session?: number;
    id_roleplaying?: string;
    id_lesson?: string;
    read_mode: boolean = false;

    user?: User;
    lesson?: Lesson;

    // Temporal values
    ask_to_teacher: boolean = false;

    avatar: Avatar;
    id_assistant: string;
    id_assistant_variant: string;

    time_to_use: number;
    remaining_time: number;

    constructor(data: Partial<Chat> = {}) {
        super();

        if (data.messages) {
            Object.assign(this, data);
            this.statistics = new StatisticsChat(data.statistics ?? {});
            this.updateTotalTime(0);
            
            let auxMessage = this.messages;
            this.messages = [];

            this.addMessages(data.messages);
            
            if (this.messages.length > 1) {
                this.messages.forEach(m => { m.mute = true });
            }
        }
    }

    addOptimisticMessage(text: string) {
        let optimisticMessage: Message = new Message();
        optimisticMessage.is_optimistic = true;
        optimisticMessage.message = text;
        optimisticMessage.role = 'user';

        this.messages.push(optimisticMessage);
    }

    removeOptimisticMessages() {
        this.messages = this.messages.filter(message => !message.is_optimistic);
    }

    addMessages(messages: Message[]) {
        messages.forEach(m => this.addMessage(m));

        this.messages.forEach((m: Message, index: number) => {
            if (m.isUserRole()) {
                if (m.hasError() && (index+1) < this.messages.length) {
                    m.calculateStatistics(this.messages[index+1]);
                } else {
                    m.calculateStatistics();
                }
            }
        });
    }

    addMessage(m: Message) {
        let newMessage = new Message(m);
        this.messages.push(newMessage);
        this.calculateStatistics();
    }

    getLastAssistantMessage(): Message {
        // Iterate through messages array from end to start
        for (let i = this.messages.length - 1; i >= 0; i--) {
            if (this.messages[i].role === 'assistant') {
                return this.messages[i];
            }
        }
        return null;
    }

    getLastUserMessage(): Message {
        // Iterate through messages array from end to start
        for (let i = this.messages.length - 1; i >= 0; i--) {
            if (this.messages[i].role === 'user') {
                return this.messages[i];
            }
        }
        return null;
    }

    getLastMessage(): Message {
        if (this.messages.length) {
            return this.messages[this.messages.length - 1];
        }
        return null;
    }

    setLastMessage(message: Message) {
        this.messages[this.messages.length - 1] = message;
    }

    getWhiteList() {
        return this.context_variables.avatar_name;
    }

    updatePercentage(p: any) {
        if (p !== null && p !== undefined) {
            this.percentage_of_completion = p;
        }
    }

    isCompleted() {
        if (this.percentage_of_completion) {
            return this.percentage_of_completion === 1;
        }
        return false;
    }

    calculateStatistics() {
        this.statistics = new StatisticsChat();
        this.calculateWPM();
        this.calculateSpellingQualityPercentage();
        this.calculateLexicalDiversity();
        this.calculateAssistantSpeak();
        this.calculateUserParticipation();
    }

    calculateWPM() {
        
        this.messages.forEach(message => {

            if (message.role === 'user') {
                let text = message.message.trim();
                let words = text.split(/\s+/);
    
                this.statistics.words_user_quantity += words.length;
    
                if (message.has_audio) {
                    this.statistics.total_words_speaked += words.length;
                    this.statistics.total_seconds_speaked += message.audio_time;
                }
            }
        });
        
        if (this.statistics.total_seconds_speaked > 0) {
            this.statistics.wpm = Math.ceil(this.statistics.total_words_speaked / (this.statistics.total_seconds_speaked / (60)));
        }
    }

    calculateSpellingQualityPercentage() {
        this.messages.forEach((message, index) => {
            if (message.role === 'user') {
                if (message.is_correct) {
                    this.statistics.corrected_words_quantity += message.message.trim().split(/\s+/).length;
                } else {
                    if (this.messages[index + 1]) {
                        let nextMessage = this.messages[index + 1];
                        this.statistics.corrected_words_quantity += nextMessage.errors.correction?.trim().split(/\s+/).length;
                        this.statistics.words_error_quantity += levenshteinDistanceWords(message.message, nextMessage.errors.correction);
                    }
                }
            }
        });

        if (this.statistics.corrected_words_quantity > 0) {
            this.statistics.spelling_quality_percentage = Math.ceil(100 - (this.statistics.words_error_quantity / this.statistics.corrected_words_quantity) * 100);
            this.statistics.spelling_quality_percentage = this.statistics.spelling_quality_percentage < 0 ? 0 : this.statistics.spelling_quality_percentage;
        } else {
            this.statistics.spelling_quality_percentage = 100;
        }

        

    }

    calculateLexicalDiversity() {
        let tokens: string[] = [];
        
        this.messages.forEach((message) => {
            if (message.role === 'user') {
                // Split and convert to lowercase
                const words = message.message.toLowerCase().split(/\s+/);
                // Filter out whitelist words
                const significantWords = words.filter(word => !COMMON_WORDS_WHITELIST.has(word));
                tokens = tokens.concat(significantWords);
            }
        });

        if (tokens.length > 0) {
            const uniqueWords = new Set(tokens);
            this.statistics.lexical_diversity_percentage = Math.ceil(100 * uniqueWords.size / tokens.length);
        }
    }

    calculateAssistantSpeak() {
        this.messages.forEach((message) => {
            if (message.role === 'assistant' && message.message) {
                this.statistics.words_assistant_quantity += message.message.trim().split(/\s+/).length;
            }
        });

        this.statistics.total_time_listening += Math.ceil(this.statistics.words_assistant_quantity * 60 / 150);
    }

    calculateUserParticipation() {
        const totalWords = this.statistics.words_user_quantity + this.statistics.words_assistant_quantity;
        if (totalWords > 0) {
            this.statistics.user_participation_percentage = Math.ceil((this.statistics.words_user_quantity * 100) / totalWords);
        }
    }

    isLesson(): boolean {
        return this.id_lesson !== null;
    }

    isRoleplaying(): boolean {
        return this.id_roleplaying !== null;
    }

    updateTotalTime(timeAdded: number) {
        this.total_time += timeAdded;

        const hours: number = Math.floor(this.total_time / 3600);
        const minutes: number = Math.floor((this.total_time % 3600) / 60);
        const remainingSeconds: number = this.total_time % 60;

        const hoursString: string = hours < 10 ? '0' + hours : hours.toString();
        const minutesString: string = minutes < 10 ? '0' + minutes : minutes.toString();
        const secondsString: string = remainingSeconds < 10 ? '0' + remainingSeconds : remainingSeconds.toString();

        this.total_time_formated = `${hoursString}:${minutesString}:${secondsString}`;
    }

    chatAllowedToResponse() {
        if (this.getLastMessage()?.allow_response !== null) {
            return true;
        }
        return this.getLastMessage()?.allow_response;
    }


    addAssessment(id_message: string, assessment: AssessmentResponse) {
        this.messages.forEach(m => {
            if (m.id == id_message) {
                m.assessment = new AssessmentResponse(assessment);
            }
        });
    }
}

export class ChatVariables extends RolePlaying {
    allow_input_chat?: boolean = true;
    
    constructor(data: Partial<ChatVariables> = {}) {
        super(data);
        if (data.avatar) {
            this.avatar = new Avatar(data.avatar);
        }
    }
}