import { inject, Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({providedIn: 'root'})
export class IconsService
{
    /**
     * Constructor
     */
    constructor()
    {
        const domSanitizer = inject(DomSanitizer);
        const matIconRegistry = inject(MatIconRegistry);

        matIconRegistry.addSvgIconSetInNamespace('mat_solid', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/material-solid.svg'));
        matIconRegistry.addSvgIconSetInNamespace('heroicons_solid', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/heroicons-solid.svg'));

        matIconRegistry.addSvgIcon('google', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/google.svg'));
		matIconRegistry.addSvgIcon('facebook', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/facebook.svg'));
		matIconRegistry.addSvgIcon('apple', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/apple.svg'));
        matIconRegistry.addSvgIcon('search', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/search.svg'));
        matIconRegistry.addSvgIcon('arrow_left', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/arrow_left.svg'));
        matIconRegistry.addSvgIcon('arrow_right', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/arrow_right.svg'));
        matIconRegistry.addSvgIcon('add', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/add.svg'));
        matIconRegistry.addSvgIcon('play', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/play.svg'));
        matIconRegistry.addSvgIcon('stop', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/stop.svg'));
        matIconRegistry.addSvgIcon('send', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/send.svg'));
        matIconRegistry.addSvgIcon('mic', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/mic.svg'));
        matIconRegistry.addSvgIcon('dictionary', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/dictionary.svg'));
        matIconRegistry.addSvgIcon('emoji_language', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/emoji_language.svg'));
        matIconRegistry.addSvgIcon('arrow_back', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/arrow_back.svg'));
        matIconRegistry.addSvgIcon('chevron_right', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/chevron_right.svg'));
        matIconRegistry.addSvgIcon('chevron_left', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/chevron_left.svg'));
        matIconRegistry.addSvgIcon('more_vert', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/more_vert.svg'));
        matIconRegistry.addSvgIcon('delete', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/delete.svg'));
        matIconRegistry.addSvgIcon('lock', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/lock.svg'));
        matIconRegistry.addSvgIcon('timer', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/timer.svg'));
        matIconRegistry.addSvgIcon('pause', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/pause.svg'));
        matIconRegistry.addSvgIcon('notifications', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/notifications.svg'));
        matIconRegistry.addSvgIcon('trending_up', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/trending_up.svg'));
        matIconRegistry.addSvgIcon('close', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/close.svg'));
        matIconRegistry.addSvgIcon('person', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/person.svg'));
        matIconRegistry.addSvgIcon('mail', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/mail.svg'));
        matIconRegistry.addSvgIcon('eye_open', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/eye_open.svg'));
        matIconRegistry.addSvgIcon('eye_close', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/eye_close.svg'));
        matIconRegistry.addSvgIcon('info', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/info.svg'));
        matIconRegistry.addSvgIcon('student', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/student.svg'));
        matIconRegistry.addSvgIcon('teacher', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/teacher.svg'));
        matIconRegistry.addSvgIcon('add_reaction', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/add_reaction.svg'));
        matIconRegistry.addSvgIcon('clear_all', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/clear_all.svg'));
        matIconRegistry.addSvgIcon('check', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/check.svg'));
        matIconRegistry.addSvgIcon('logout', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/logout.svg'));
        matIconRegistry.addSvgIcon('school', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/school.svg'));
        matIconRegistry.addSvgIcon('verified', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/verified.svg'));
        matIconRegistry.addSvgIcon('viewfinder', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/viewfinder.svg'));
        matIconRegistry.addSvgIcon('save', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/save.svg'));
        matIconRegistry.addSvgIcon('monitoring', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/monitoring.svg'));
        matIconRegistry.addSvgIcon('edit', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/edit.svg'));
        matIconRegistry.addSvgIcon('data_thresholding', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/data_thresholding.svg'));
        matIconRegistry.addSvgIcon('crisis_alert', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/crisis_alert.svg'));
        matIconRegistry.addSvgIcon('exercise', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/exercise.svg'));
        matIconRegistry.addSvgIcon('point_scan', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/point_scan.svg'));
        matIconRegistry.addSvgIcon('spellcheck', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/spellcheck.svg'));
    }
}
// 