import { Component } from '@angular/core';

@Component({
	selector: 'download-app',
	standalone: true,
	imports: [],
	templateUrl: './download-app.component.html',
	styleUrl: './download-app.component.scss'
})
export class DownloadAppComponent {
	private readonly appId = 'com.metaeducation.gotalks';
  
	openPlayStore(): void {
		// URL para la app en Play Store
		const playStoreUrl = `market://details?id=${this.appId}`;
		const webUrl = `https://play.google.com/store/apps/details?id=${this.appId}`;

		try {
		// Intenta abrir la app nativa de Play Store
		window.location.href = playStoreUrl;
		
		// Si después de un pequeño delay no se abrió la app, 
		// redirige al sitio web
		setTimeout(() => {
			window.location.href = webUrl;
		}, 500);
		} catch {
			// Si hay algún error, abre directamente el sitio web
			window.location.href = webUrl;
		}
	}
}
