import { base } from 'app/types/base.type';
import { School } from 'app/types/school/school.type';
import { Chat } from 'app/types/chat/chat.type';
import { Classroom } from 'app/types/classroom/classroom.type';
import { ClassSession } from 'app/types/schedule/schedule.type';

export class User extends base {
    name: string
    lastname?: string
    password?: string
    email: string
    email_verified?: boolean
    birthday?: string
    phone?: string
    gender?: string
    profile?: Profile
    recomended_by?: string
    enable?: boolean
    status?: string
    main_role_selected?: boolean
    google_register?: boolean
    google_avatar?: string
    google_token?: string
    id_school?: string
    id_classroom?: string
    school?: School
    classroom?: Classroom
    id_author?: string
    sessions?: ClassSession[]
    homeworks?: ClassSession[]
    google_json?: string;
    name_role?: string;
    roles?:Role[]
    in_classroom?: boolean // flag if the user is a teacher an if the user has a classroom
    preferences: Preferences
    progress_lesson: ProgressLesson[]

    chat?: Chat

    constructor(data: Partial<User> = {}) {
        super();
        if (data) {
            Object.assign(this, data);
            if (this.chat) {
                this.chat = new Chat(this.chat);
            }
        }
    }

    unlookSessions() {
        if (this.sessions) {
            this.sessions.forEach( s => {
                s.resources.forEach( r => {
                    if (r.entity == 'roleplaying') {
                        
                    }
                } )
            } )
        }
    }

    private searchRole(roleName: string) {
        let found = false;
        if (this.roles) {
            this.roles.forEach( r => {
                if (r.name) {
                    if (r.name == roleName) {
                        found = true;
                    }
                }
            } )
        }

        return found
    }

    isSchoolStudent() {
        return this.searchRole('school_student');
    }

    isStudent() {
        return this.searchRole('student');
    }

    isTeacher() {
        return this.searchRole('teacher');
    }

    isCoAdmin() {
        return this.searchRole('co-admin');
    }

    isAdmin() {
        return this.searchRole('admin');
    }

    getProgress(id_lesson: string) {

        let progress = 0;

        this.progress_lesson.forEach( pl => {
            if (pl.id_lesson == id_lesson) {
                progress = pl.progress;
            }
        } )

        return progress;
    }

    public getFullName() {
        return this.name + ' ' + this.lastname
    }
}

export class Profile {
    goals_and_interests: string[]
    is_student: boolean
    is_worker: boolean
    about_his_studies: string[]
    about_his_works: string[]
    hobbies: string[]
    best_times_to_study: ProfileSchedule[]
}

export class Preferences {
    skip_profile?: boolean = false;
    lang_learning: string = "en-GB"
}

export class ProfileSchedule {
    hour_ini: string
    hour_end: string
}

export class ProgressLesson {
    id_lesson: string
    progress: number
}

export class Role {
    name: string
    label?: string
}