import { Component, Input, OnInit } from '@angular/core';

type ColorType = 'green' | 'blue';

@Component({
	selector: 'progress-bar',
	standalone: true,
	imports: [ ],
	templateUrl: './progress-bar.component.html',
	styleUrl: './progress-bar.component.scss',
	host: {
		class: "overflow-hidden flex flex-row bg-transparent"
	}
})
export class ProgressBarComponent implements OnInit {
	@Input() progress: number = 0;
	@Input() color: ColorType = 'green';
	@Input() height: number = 6;

	ngOnInit(): void {
		this.progress = Math.ceil(this.progress);
	}

	getColor(color: ColorType): string {
		const colorMap = {
			green: '#24DB24',
			blue: '#3D85FA'
		};
		return colorMap[color];
	}

	getBackgroundColor(color: ColorType): string {
		const colorMap = {
			green: '#0D1548',
			blue: '#0D1548'
		};
		return colorMap[color];
	}
}
