import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class WizardService {

    onAllowedContinue: BehaviorSubject<boolean>;
    onContinue: BehaviorSubject<boolean>;
    onAllowedBack: BehaviorSubject<boolean>;
    onBack: BehaviorSubject<boolean>;
    percentageProgress$: BehaviorSubject<number>;
    wizardEnded$: BehaviorSubject<boolean>;
    lastStep: BehaviorSubject<boolean>;
    onBiggerWidth: BehaviorSubject<boolean>;

    constructor() {
        this.onAllowedContinue = new BehaviorSubject(false);
        this.onContinue = new BehaviorSubject(true);
        this.onAllowedBack = new BehaviorSubject(false);
        this.onBack = new BehaviorSubject(false);
        this.percentageProgress$ = new BehaviorSubject(0);
        this.wizardEnded$ = new BehaviorSubject(false);
        this.lastStep = new BehaviorSubject(false);
        this.onBiggerWidth = new BehaviorSubject(false);
    }
}
