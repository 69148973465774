import { Component, OnInit, OnDestroy } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { NgIf, NgClass } from '@angular/common';
import { MatIcon } from '@angular/material/icon'

import { asyncScheduler } from 'rxjs';
import { observeOn } from 'rxjs/operators';

import { DeviceDetectorService } from 'app/services/device-detector.service';
import { SpeechService } from 'app/services/speech.service';
import { WizardService } from 'app/services/wizard.service';

import { BackButtonComponent } from 'app/modules/shared/back-button/back-button.component';
import { BackgroundGridComponent } from 'app/modules/shared/background-grid/background-grid.component';
import { CircleLoaderComponent } from 'app/modules/shared/circle-loader/circle-loader.component';
import { ProgressBarComponent } from 'app/modules/shared/progress-bar/progress-bar.component';
import { ScreenSizeComponent } from 'app/modules/shared/screen-size/screen-size.component';
import { StandardButtonComponent } from 'app/modules/shared/standard-button/standard-button.component';

@Component({
    selector: 'app-wizard-layout',
    standalone: true,
    imports: [
        RouterOutlet,
        NgIf, NgClass,
        MatIcon,
        ScreenSizeComponent, BackgroundGridComponent, BackButtonComponent, ProgressBarComponent, StandardButtonComponent, CircleLoaderComponent],
    templateUrl: './wizard-layout.component.html',
    styleUrl: './wizard-layout.component.scss'
})
export class WizardLayoutComponent implements OnInit, OnDestroy {

    percentage: number = 0;
    allowedContinue: boolean = false;
    allowedBack: boolean = false;
    wizardEnded: boolean = false;
    lastStep: boolean = false;
    biggerWidth: boolean = false;

    constructor(
        public ddss: DeviceDetectorService,
        public speechService: SpeechService,
        public wizardService: WizardService
    ) { }

    ngOnInit(): void {
        this.wizardService.onBack.next(false);
        this.wizardService.percentageProgress$.subscribe(percentage => this.percentage = percentage);
        this.wizardService.onAllowedContinue.pipe(
            observeOn(asyncScheduler)
        ).subscribe(allowed => this.allowedContinue = allowed);
        this.wizardService.onAllowedBack.subscribe(allowed => this.allowedBack = allowed);
        this.wizardService.wizardEnded$.subscribe(wizardEnded => this.wizardEnded = wizardEnded);
        this.wizardService.lastStep.subscribe(lastStep => this.lastStep = lastStep);
        this.wizardService.onBiggerWidth.subscribe(biggerWidth => this.biggerWidth = biggerWidth);
    }

    ngOnDestroy(): void {
        this.speechService.stop();
    }

    click() {
        if (this.allowedContinue) {
            this.wizardService.onContinue.next(true)
        }
    }

    clickBack() {
        if (this.allowedBack) {
            this.wizardService.onBack.next(true);
        }
    }
}
