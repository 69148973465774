import { Component, Input } from '@angular/core';
import { RouterOutlet, RouterLink, RouterLinkActive } from '@angular/router';
import { NgTemplateOutlet, NgIf, NgFor, NgClass } from '@angular/common';

import { User } from 'app/types/user.type';

import { MatIconModule } from '@angular/material/icon';

import { UserService } from 'app/services/user.service';
import { DeviceDetectorService } from 'app/services/device-detector.service';

import { AvatarLetterComponent } from 'app/modules/shared/avatar-letter/avatar-letter.component';
import { BackgroundGridComponent } from 'app/modules/shared/background-grid/background-grid.component';
import { UserMenuComponent } from 'app/modules/shared/user-menu/user-menu.component';
import { ScreenSizeComponent } from 'app/modules/shared/screen-size/screen-size.component';


interface Route {
	text: string
	icon?: string
	iconRoute?: string
	route: string
	disabled: boolean
}

@Component({
	selector: 'general-menu',
	standalone: true,
	imports: [
		NgIf, NgFor, NgClass,
		RouterOutlet, NgTemplateOutlet, MatIconModule, RouterLink, RouterLinkActive,
		AvatarLetterComponent, UserMenuComponent, ScreenSizeComponent, BackgroundGridComponent ],
	templateUrl: './general-menu.component.html',
	styleUrl: './general-menu.component.scss'
})
export class GeneralMenuComponent {
	user: User;

	@Input() routes: Route[] = [];
	@Input() breakPoint: number = 4;
	@Input() logoLabel: string;

	// Separar las rutas en dos grupos
	defaultRoutes = this.routes.slice(0, 3);
	subscriptionRoutes = this.routes.slice(3);
	/**
     * Constructor
     */
    constructor(
		public ddss: DeviceDetectorService,
        private userService: UserService
    )
    {
    }

	ngOnInit(): void
    {
		// Subscribe to user changes
        this.user = this.userService.user;
		this.defaultRoutes = this.routes.slice(0, this.breakPoint);
		this.subscriptionRoutes = this.routes.slice(this.breakPoint);
	}

	goToHome() {}
}
