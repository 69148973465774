<div class="min-h-screen h-screen max-h-screen flex flex-col bg-background overflow-hidden relative">

    <!-- Background-->
    <!--<div *ngIf="!dd.isLessEqual('sm')" class="w-full flex flex-col md:space-y-8 xl:-space-y-16 md:z-10 absolute bottom-0">
		<div *ngFor="let _ of getArray(); let i = index" class="w-full line-gradient-horizontal"></div>
	</div>-->

    <background-grid class="w-full h-full absolute z-10"></background-grid>

    <!--<div class="absolute radial-full z-20"></div>-->

    <div class="container mx-auto relative flex flex-col h-full px-0 md:px-10 lg:px-5 xl:px-5 2xl:px-10 z-30">

        <!-- Header -->
        <div *ngIf="dd.isLessEqual('sm')"
            class="min-h-40 h-40 sm:min-h-24 sm:h-24 xl:min-h-32 xl:h-3 2xl:min-h-48 2xl:h-48
					flex flex-row w-full justify-center md:justify-between items-center">
            <img class="w-32 sm:w-28 xl:w-48"
                src="assets/images/logo/logo-negative-rectangle.png"
                alt="Go Talks logo">
        </div>

        <!-- Avatar + Sign In -->
        <div class="flex flex-row w-full relative flex-grow h-full
					space-x-0 md:space-x-0 lg:space-x-10 xl:space-x-10 2xl:space-x-0">
            <!-- Avatar -->
            <div *ngIf="!dd.isLessEqual('sm')"
                class="w-1/2 flex flex-col z-20 flex-grow relative items-center">

                <!-- Header -->
                <div *ngIf="!dd.isLessEqual('sm')"
                    class="min-h-40 h-40 sm:min-h-24 sm:h-24 xl:min-h-32 xl:h-3 2xl:min-h-36 2xl:h-36
						flex flex-row w-full justify-center md:justify-between items-center">
                    <img class="w-32 sm:w-28 xl:w-48 2xl:w-36"
                        src="assets/images/logo/logo-negative-rectangle.png"
                        alt="Go Talks logo">
                </div>

                <img *ngIf="variant" [src]="variant.picture.url" alt="" class="absolute w-1/2 video-container md:top-5 lg:top-4 xl:top-20 2xl:top-40">

                <!--<video *ngIf="variant" #videoPlayer
                    preload="auto"
                    playsinline
                    muted
                    loop
                    class="absolute w-1/2 video-container md:top-5 lg:top-4 xl:top-20 2xl:top-40">
                    <source [src]="variant.mute.url"
                        type="video/webm">
                    No fue posible cargar el avatar
                </video>-->


                <div class="flex w-full items-center justify-center flex-grow">
                    <div class="w-1/2 flex-col">
                        <div class="flex h-20 items-center justify-start"></div>
                        <div class="flex h-20 items-center justify-start">
                            <div class="
								 md:px-10 md:py-4 md:text-4xl
								 lg:px-8 lg:py-3 lg:text-xl
								 xl:px-10 xl:py-4 xl:text-3xl xl:p-3
								 2xl:px-10 2xl:py-4 2xl:text-3xl 2xl:p-3
								rounded-full z-99
								text-white blur-background border
								border-gt-border-button-light">genAi</div>
                        </div>
                        <div class="flex h-20 items-center justify-start"></div>
                    </div>

                    <div class="w-1/2 flex-col">
                        <div class="flex h-20 items-center justify-end">
                            <div class="
								 md:px-10 md:py-4 md:text-4xl
								 lg:px-8 lg:py-3 lg:text-xl
								 xl:px-10 xl:py-4 xl:text-3xl xl:p-3
								 2xl:px-10 2xl:py-4 2xl:text-3xl 2xl:p-3
								rounded-full z-99
								text-white blur-background border
								border-gt-border-button-light">The future</div>
                        </div>
                        <div class="flex h-20 items-center justify-end"></div>
                        <div class="flex h-20 items-center justify-end"></div>
                        <div class="flex items-center justify-end">
                            <div class="
								 md:px-10 md:py-4 md:text-4xl
								 lg:px-8 lg:py-3 lg:text-xl
								 xl:px-10 xl:py-4 xl:text-3xl xl:p-3
								 2xl:px-10 2xl:py-4 2xl:text-3xl 2xl:p-3
								rounded-full z-99
								text-white blur-background border
								border-gt-border-button-light">English Pro</div>
                        </div>
                    </div>
                </div>


            </div>

            <!-- Form -->
            <div class="w-full md:w-1/2 flex flex-col p-0 md:p-0 lg:px-0 xl:p-0 2xl:pl-32 h-full">

                <!-- Header -->
                <div *ngIf="!dd.isLessEqual('sm')"
                    class="min-h-40 h-40 sm:min-h-24 sm:h-24 xl:min-h-32 xl:h-3 2xl:min-h-36 2xl:h-36
					flex flex-row w-full justify-center md:justify-end items-center">
                    <div class="hidden xl:block">
                        <div class="flex flex-row">
                            <download-app></download-app>
                        </div>
                    </div>
                </div>

                <div [ngStyle]="{'border-radius': '2.5rem', 'important': !dd.isLessEqual('sm')}"
                    class="w-full md:max-w-2xl shrink md:border md:border-gt-border items-center justify-center
							px-4 md:px-10 xl:px-16 md:py-8 xl:py-12 bg-background">

                    <!-- Buttons -->
                    <router-outlet></router-outlet>

                </div>
            </div>
        </div>
    </div>
    <screen-size></screen-size>
</div>