import { Component, OnInit } from '@angular/core';
import { NgIf } from '@angular/common';

import { environment } from 'environments/environment';

@Component({
	selector: 'screen-size',
	standalone: true,
	imports: [ NgIf ],
	templateUrl: './screen-size.component.html',
	styleUrl: './screen-size.component.scss'
})
export class ScreenSizeComponent implements OnInit {
	show: boolean = true;

	ngOnInit(): void {
		this.show = !environment.production;
	}
}
