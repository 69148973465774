export interface RelevanceDetails {
    matches_question_type: boolean;
    uses_appropriate_tense: boolean;
    contains_key_elements: boolean;
    explanation: string;
}

export interface EffortDetails {
    uses_complete_sentences: boolean;
    expands_on_basic_answer: boolean;
    explanation: string;
    word_count: number;
}

export interface GrammarFocusDetails {
    uses_target_grammar: boolean;
    uses_it_correctly: boolean;
    explanation: string;
}

export class AssessmentResponse {
    
    relevance_details: RelevanceDetails;
    effort_details: EffortDetails;
    grammar_focus_details?: GrammarFocusDetails;
    
    suggestions: string[];
    
    relevanceScore: number = 0;
    effortScore: number = 0;
    grammarScore: number = 0;

    constructor(data: any) {
        this.relevance_details = data.relevance_details;
        this.effort_details = data.effort_details;
        this.grammar_focus_details = data.grammar_focus_details || null;
        this.suggestions = data.suggestions;

        // Calculate relevance score - 0.5 points per true
        this.relevanceScore = Object.entries(this.relevance_details)
            .filter(([key, value]) => typeof value === 'boolean' && value === true)
            .length * 0.5;

        // Calculate effort score - 0.5 points per true
        this.effortScore = Object.entries(this.effort_details)
            .filter(([key, value]) => typeof value === 'boolean' && value === true)
            .length * 0.5;

        // Calculate grammar score - 0.5 points per true only if grammar_focus_details exists
        if (this.grammar_focus_details) {
            this.grammarScore = Object.entries(this.grammar_focus_details)
                .filter(([key, value]) => typeof value === 'boolean' && value === true)
                .length * 0.5;
        }
    }
}