import { Injectable } from '@angular/core';
import { BaseService } from 'app/services/base.service';
import { AppService } from 'app/services/app.service';
import { BehaviorSubject } from 'rxjs';
import { User } from 'app/types/user.type';
import { Scope } from 'app/types/scope.type';
import { EncryptService } from 'app/services/encrypt.service';

@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseService {


	override base_path: string = 'user/';
  	onUserCreated: BehaviorSubject<any>;
	onChangeAccent: BehaviorSubject<string>;
	requestReloadMenu: BehaviorSubject<any>;

	constructor(
		override _appService: AppService,
		private encryptService: EncryptService
	) {    
		super(_appService);

    	this.onUserCreated = new BehaviorSubject(null);
		this.requestReloadMenu = new BehaviorSubject(null);
		this.onChangeAccent = new BehaviorSubject("en-GB");
	}

	/**
     * Setter & getter for access token
     */
    set user(user: User)
    {
		this.encryptService.set(user, 'gt-user');
    }

    get user(): User
    {
		let user = this.encryptService.get('gt-user');
		return new User(user);
    }

	/**
     * Setter & getter for access token
     */
    set scope(scope: Scope)
    {
		this.encryptService.set(scope, 'gt-scope');
    }

    get scope(): Scope
    {
		let scope = this.encryptService.get('gt-scope');
		return scope ?? new Scope();
    }

	refresh() {
		return this._appService.post( 'auth/' +  'refresh', {}, "");
	}

	getReduced( payload: any ) {
		return this._appService.post( this.base_path +  'get-reduced', payload, "");
	}

	override getAll( payload: any ) {
		return this._appService.post( this.base_path +  'get-all', payload, "");
	}

	override create( payload: any ) {
		return this._appService.post( this.base_path +  'create', payload, "");
	}

	override update( payload: any ) {
		return this._appService.post( this.base_path +  'update', payload, "");
	}

	checkEmail( payload: any ) {
		return this._appService.post( this.base_path +  'check-email', payload, "");
	}

	confirmMainRoleSelected( payload = {role: 'student'} ) {
		return this._appService.post( this.base_path +  'confirm-main-role-selected', payload, "");
	}

	hasSessions(): Boolean {
		return this.user.sessions?.length > 0 ? true : false;
	}


	sendCodeToDelete() {
		return this._appService.post( this.base_path +  'send-code-to-delete', null, "");
	}

	override delete(payload: any) {
		return this._appService.post(this.base_path + 'delete', payload);
	}
}
