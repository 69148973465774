import { base, levenshteinDistanceWords } from 'app/types/base.type';
import { StatisticsMessage } from 'app/types/chat/statistics.type';
import { Picture } from 'app/types/picture/picture.type';
import { AssessmentResponse } from 'app/types/chat/assessment-response.type';

export class Message extends base {
    id_chat?: string;
    ai_role: string;
    role: string;
    has_audio?: boolean;
    audio_time?: number;
    is_correct?: boolean = true;
    message: string;
    json?: JsonMessage;
    prompt_tokens?: boolean;
    completion_tokens?: boolean;
    total_tokens?: boolean;
    errors?: Error;
    audio?: any;
    percentage_of_completion?: number;
    mute?: boolean = false;
    statistics?: StatisticsMessage;
    allow_response: boolean = true;
    is_json?: boolean = false;
    is_optimistic?: boolean = false;

    assessment?: AssessmentResponse;

    constructor(data: Partial<Message> = {}) {
        super();
        Object.assign(this, data);
        this.statistics = new StatisticsMessage();
        this.has_audio = this.audio_time > 0;

        if (this.assessment) {
            this.assessment = new AssessmentResponse(this.assessment);
        }
    }

    isUserRole() {
        return this.role === 'user';
    }

    isAssistantRole() {
        return this.role === 'assistant';
    }

    hasError() {
        return !this.is_correct;
    }

    calculateStatistics(nextMessage: Message = null) {
        this.calculateWPM();
        if (nextMessage) {
            this.calculateSpellingQualityPercentage(nextMessage);
        }
    }

    calculateWPM() {
        const words = this.message.trim().split(/\s+/);
        this.statistics.words_quantity = words.length;

        if (this.has_audio) {
            this.statistics.wpm = Math.ceil(words.length / (this.audio_time / 60));
        }
    }

    calculateSpellingQualityPercentage(nextMessage: Message) {
        if (this.is_correct) {
            this.statistics.spelling_quality_percentage = 100;
        } else {
            this.statistics.words_error_quantity = levenshteinDistanceWords(this.message, nextMessage.errors.correction);
            const corrected_words_quantity = nextMessage.errors.correction.trim().split(/\s+/).length;
            this.statistics.spelling_quality_percentage = Math.ceil(100 - (this.statistics.words_error_quantity / corrected_words_quantity) * 100);
            this.statistics.spelling_quality_percentage = this.statistics.spelling_quality_percentage < 0 ? 0 : this.statistics.spelling_quality_percentage;
        }
    }
}

export class Error {
    errors?: ErrorDetail[]
    is_correct: boolean
    correction?: string
    //recommendation: string
    options?: string[]
    reason?: string

    answered_correctly?: boolean
    suggestion?: boolean
    example?: string
}

export class ErrorDetail {
    segment?: string
    type?: string
    reason?: string
    correction?: string
}

export class JsonMessageExercise {
    fillInTheBlank: JsonMessageExerciseFillInTheBlank
    multipleChoice: JsonMessageExerciseMultipleChoice
    selectInText: JsonMessageExerciseSelectInText
    matching: JsonMessageExerciseMatching
    trueFalse: JsonMessageExerciseTrueFalse
    orderWords: JsonMessageExerciseOrderWords
    imageWordMatching: JsonMessageImageWordMatching
    shortAnswer: JsonMessageShortAnswer
}

export class JsonMessageExerciseFillInTheBlank {
    instructions?: string
    statement_completed?: string
    statement?: string
    wrong_options?: string[]
    correct_options?: string[]
}

export class JsonMessageExerciseMultipleChoice {
    instructions?: string
    prompt?: string
    options?: string[]
    correct_options?: string[]
    explanation?: string
}

export class JsonMessageExerciseSelectInText {
    instructions?: string
    text?: string
    correct_selections: {word: string, word_index: number}[]
    explanation?: string
}

export class JsonMessageExerciseMatching {
    instructions?: string
    pairs?: {left: string, right: string}[]
}

export class JsonMessageExerciseTrueFalse {
    instructions?: string
    statements?: {statement: string, is_true: boolean}[]
}

export class JsonMessageExerciseOrderWords {
    instructions?: string
    words: string[]
    correct_order: string[]
}

export class JsonMessageImageWordMatching {
    instructions?: string
    image_description: string
    correct_word: string
    wrong_words: string[]
}

export class JsonMessageShortAnswer {
    instructions?: string
    question: string
    correct_answer: string
}

export class JsonMessage extends JsonMessageExercise{

    constructor(data: Partial<JsonMessage> = {}) {
        super();
        Object.assign(this, data);
    }

    example?: string
    example_with_errors?: string
    description?: string
    correct_example?: string
    text?: string
    type?: string

    columns_name?: any
    rows?: any

    id_picture?: string
    picture?: Picture    
    
    verb?: string
    subject?: string
    predicate?: string
    pronoun?: string
    noun?: string
    adjective?: string
    adverb?: string
    preposition?: string
    conjunction?: string
    determiner?: string
    direct_object?: string
    indirect_object?: string
    circumstantial?: string
    main_word?: string
}

export class MessageExerciseOption {
    isWrong: boolean = false
    text: string
    selected: boolean = false
    originalIndex: number
}


export class WordItem {
    word: string = "";
    index: number;
}
