import { Injectable } from '@angular/core';
import { WhisperWord } from 'app/types/wishper/wishper.type';

@Injectable({
  	providedIn: 'root'
})

export class SpeechTextProcessorService {

  	constructor() { }

	private normalizeText(text: string): string[] {
		return text
		  .replace(/[\u{1F000}-\u{1FFFF}\u{2600}-\u{27BF}\u{2B50}\u{2934}-\u{2935}\u{2B05}-\u{2B07}\u{2934}-\u{2935}\u{3297}\u{3299}\u{303D}\u{00A9}\u{00AE}\u{2122}\u{23F0}\u{23F3}]/gu, '')
		  .replace(/\s+/g, ' ')
		  .trim()
		  .split(' ');
	}
	
	processText(originalText: string, whisperResponse: WhisperWord[]): WhisperWord[] {
		const originalWords = this.normalizeText(originalText);
		const processedWords: WhisperWord[] = [];
		let whisperIndex = 0;
		
		for (let i = 0; i < originalWords.length; i++) {
		  if (whisperIndex >= whisperResponse.length) break;
		  
		  const originalWord = originalWords[i].toLowerCase();
		  const whisperWord = whisperResponse[whisperIndex].word.toLowerCase();
		  
		  // Caso: Whisper combinó palabras
		  if (whisperWord.includes(originalWord) && whisperWord.length > originalWord.length) {
			const combinedWords = whisperWord.split(/[\s.]+/);
			const timePerWord = (whisperResponse[whisperIndex].end - whisperResponse[whisperIndex].start) / combinedWords.length;
			
			processedWords.push({
			  word: originalWords[i],
			  start: whisperResponse[whisperIndex].start + (timePerWord * combinedWords.indexOf(originalWord)),
			  end: whisperResponse[whisperIndex].start + (timePerWord * (combinedWords.indexOf(originalWord) + 1))
			});
		  }
		  // Caso: Whisper separó palabras
		  else if (originalWord.includes(whisperWord)) {
			let combinedWord = whisperResponse[whisperIndex];
			while (
			  whisperIndex + 1 < whisperResponse.length &&
			  !originalWord.includes(combinedWord.word.toLowerCase())
			) {
			  whisperIndex++;
			  combinedWord = {
				word: originalWords[i],
				start: combinedWord.start,
				end: whisperResponse[whisperIndex].end
			  };
			}
			processedWords.push(combinedWord);
		  }
		  // Caso: Match directo
		  else {
			processedWords.push({
			  word: originalWords[i],
			  start: whisperResponse[whisperIndex].start,
			  end: whisperResponse[whisperIndex].end
			});
			whisperIndex++;
		  }
		}
		
		return processedWords;
	}
}
