import { Component, OnInit } from '@angular/core';

import { GeneralMenuComponent } from 'app/modules/shared/general-menu/general-menu.component';

interface Route {
	text: string
	icon: string
	route: string
	disabled: boolean
}

@Component({
	selector: 'app-layout',
	standalone: true,
	imports: [ GeneralMenuComponent ],
	templateUrl: './app-layout.component.html',
	styleUrl: './app-layout.component.scss'
})

export class AppLayoutComponent implements OnInit {

	routes: Route[] = [
		{text: 'Learn', icon: 'book', route: 'learn', disabled: true},
		{text: 'Practice', icon: 'desktop', route: 'practice', disabled: false},
		{text: 'Store', icon: 'gift', route: 'store', disabled: true},
		{text: 'Profile', icon: 'woman', route: 'profile', disabled: false},
		{text: 'School', icon: 'graduation-hand', route: 'school', disabled: false},
		{text: 'Subscription', icon: 'card', route: 'subscription', disabled: true},
	]

	ngOnInit(): void {
		
	}
}
