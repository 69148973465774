<div class="min-h-screen h-screen max-h-screen flex flex-col sm:flex-row bg-background overflow-hidden relative">
    <!-- Menu Bar Vertical-->
    <div *ngIf="!ddss.is.xs" class="min-h-screen bg-[#060A25] xl:w-64 2xl:w-72 flex-none">
        <div class="w-full h-full flex flex-col items-center justify-between">

            <div class="flex flex-col flex-grow w-full">
                <!-- logo -->
                <div [routerLink]="['/school/dashboard']" class="flex justify-center items-center mt-8 w-full px-3 relative cursor-pointer">
                    <img class="w-32 sm:w-16 xl:w-24 2xl:min-w-24"
                        src="assets/images/logo/logo-negative-rectangle.png"
                        alt="Go Talks logo">
                    <div *ngIf="logoLabel" class="absolute -bottom-7 p-1 px-3 rounded-xl border border-[#1F50A0] bg-[#1F50A0] text-white text-xs">
                        {{logoLabel}}
                    </div>
                </div>

                <div *ngIf="logoLabel" class="p-4"></div>

                <!-- Menu A - Default options -->
                <div class="flex flex-col w-full px-4 space-y-3 sm:px-6 sm:py-12 lg:py-6 text-[#7B7F9D]">
                    <ng-container *ngFor="let item of defaultRoutes">
                        <ng-container *ngTemplateOutlet="menuButton; context: item">
                        </ng-container>
                    </ng-container>
                </div>

                <!-- Divider -->
                <div class="w-full h-1 border-t border-[#252D5C]"></div>

                <!-- Menu B School and Suscription -->
                <div class="flex flex-col w-full px-4 space-y-3 sm:px-6 sm:py-12 lg:py-6 text-[#7B7F9D]">
                    <ng-container *ngFor="let item of subscriptionRoutes">
                        <ng-container *ngTemplateOutlet="menuButton; context: item">
                        </ng-container>
                    </ng-container>
                </div>
            </div>

            <!-- User -->
            <user-menu [user]="user"></user-menu>
        </div>
    </div>

    <!-- Header Mobile -->
    <div *ngIf="ddss.is.xs"
        class="w-full flex flex-row items-center justify-between px-7 py-5 bg-background h-[73px] border-b border-[#192153] z-0">
        <div class="flex">
            <div class="flex flex-col w-24 items-center justify-center space-y-1">
                <img class="w-14"
                    src="assets/images/logo/logo-negative-rectangle.png"
                    alt="Go Talks logo">
                <div *ngIf="logoLabel" class="text-center -bottom-5 p-0 px-2 rounded-xl border border-[#1F50A0] bg-[#1F50A0] text-white text-xs">
                    {{logoLabel}}
                </div>
            </div>
            
        </div>
        <div class="flex flex-row space-x-4">
            <div class="flex bg-[#1F50A0] rounded-full p-2 sm:p-1 items-center justify-center cursor-pointer">
                <mat-icon [svgIcon]="'notifications'"
                    class="flex items-center justify-center text-white text-[1rem]"></mat-icon>
            </div>
            <!-- User -->
            <user-menu [user]="user"></user-menu>
        </div>
        
    </div>

    <!-- Main Screen -->
    <div class="flex flex-1 sm:min-h-screen sm:h-screen w-full relative overflow-hidden">
        <div class="flex flex-grow min-h-full flex-col justify-center w-full h-full z-10 bg-background md:bg-transparent">
            <router-outlet></router-outlet>
        </div>
        <background-grid *ngIf="!ddss.is.xs" class="w-full h-full absolute z-0"></background-grid>
    </div>

    <!-- Menu Bottom -->
    <div *ngIf="ddss.is.xs"
        class="w-full flex flex-row items-center justify-around py-5 bg-[#03071C] border-t border-[#6785B6] h-[88px] z-0">

        <ng-container *ngFor="let route of routes">
            <ng-container *ngTemplateOutlet="menuButton; context: route">
            </ng-container>
        </ng-container>
    </div>

    <!-- Size Indicator -->
    <screen-size></screen-size>
</div>


<ng-template #menuButton
    let-text="text"
    let-icon="icon"
    let-route="route"
    let-iconRoute="iconRoute"
    let-disabled="disabled">
    <a [routerLink]="!disabled ? ['/' + route] : null" 
        [routerLinkActive]="'bg-[#1F50A0] text-white rounded-xl'"
        class="block xl:w-full text-current no-underline"
        [class.opacity-50]="disabled"
        [class.pointer-events-none]="disabled">
        <div
            class="flex flex-row w-full gap-2 items-center justify-center sm:justify-between relative px-1 xl:px-4 py-1 rounded-xl"
            [ngClass]="{
                'hover:bg-[#1F50A0] hover:text-white cursor-pointer': !disabled,
                'cursor-not-allowed': disabled
            }">
            <div class="flex flex-row items-center xl:gap-2 min-h-9">
                <img [ngClass]="{'size-9': icon, 'w-9 h-auto': iconRoute}"
                    [src]=" iconRoute ? iconRoute : 'assets/images/icons/' + icon + '.webp'"
                    [alt]="icon">
                <div class="hidden xl:block text-sm font-medium">
                    {{text}}
                    <span *ngIf="disabled" class="text-xs text-gray-400 ml-1">(Soon)</span>
                </div>
            </div>
            <div class="hidden 2xl:block">
                <a [routerLink]="!disabled ? ['/' + route] : null" [routerLinkActive]="'hidden'">
                    <mat-icon class="text-[16px] hidden xl:flex items-center justify-center"
                        [svgIcon]="'arrow_right'"
                        *ngIf="!disabled"></mat-icon>
                </a>
                
            </div>
        </div>
    </a>
</ng-template>