import { Injectable } from '@angular/core';

export interface WordTiming {
  word: string;
  start: number;
  end: number;
}

// Interfaces base para reglas de idioma
interface LanguageRules {
  calculateWordWeight(word: string, context: TimingContext): number;
  getDefaultPauses(): PauseRules;
}

interface PauseRules {
    betweenWords: number;
    comma: number;
    period: number;
    other: number;
    quotation: number;
}

interface TimingContext {
    isContentWord: boolean;
    isEndOfSentence: boolean;
    isAfterPause: boolean;
    position: number;
}

// Implementación específica para inglés
class EnglishRules implements LanguageRules {
    private readonly functionWords = new Set([
        'the', 'a', 'an', 'and', 'but', 'or', 'in', 'on', 'at', 'to', 'for',
        'of', 'with', 'by', 'from', 'up', 'about', 'into', 'over', 'after'
    ]);

    private readonly contractions = new Set([
        "n't", "'s", "'m", "'re", "'ll", "'ve", "'d"
    ]);

    getDefaultPauses(): PauseRules {
        return {
            betweenWords: 0.3,
            comma: 0.2,
            period: 2,
            other: 0.3,
            quotation: 0.5
        };
    }

    calculateWordWeight(word: string, context: TimingContext): number {
        let weight = this.getBaseWeight(word);
        
        // Ajustes contextuales específicos del inglés
        weight = this.applyStressRules(weight, word, context);
        weight = this.applyPositionalRules(weight, context);
        weight = this.applyContractionRules(weight, word);
        
        return weight;
    }

    private getBaseWeight(word: string): number {
        const normalizedWord = word.toLowerCase().replace(/[.,!?;:]/, '');
        
        // Palabras funcionales tienen menor peso
        if (this.functionWords.has(normalizedWord)) {
        return 0.6;
        }
        
        // Peso base para content words
        return 1.0;
    }

    private applyStressRules(weight: number, word: string, context: TimingContext): number {
        // Identificar sílabas stressed basado en patrones comunes del inglés
        if (this.hasStressPattern(word)) {
        weight *= 1.5;
        }

        // Consonant clusters aumentan el peso
        const consonantClusters = word.match(/[bcdfghjklmnpqrstvwxz]{2,}/gi);
        if (consonantClusters) {
        weight *= (1 + (0.1 * consonantClusters.length));
        }

        return weight;
    }

    private hasStressPattern(word: string): boolean {
        // Patrones simples de stress en inglés
        // Esto es una simplificación - podría mejorarse con una librería fonética
        const patterns = [
        /[A-Z]/,                    // Mayúsculas (posible énfasis)
        /ing$/,                     // Terminaciones comunes stressed
        /tion$/,
        /sion$/,
        /[aeiou]{2}/i              // Vocales dobles
        ];

        return patterns.some(pattern => pattern.test(word));
    }

    private applyPositionalRules(weight: number, context: TimingContext): number {
        if (context.isEndOfSentence) {
        weight *= 1.2;  // Final lengthening
        }
        if (context.isAfterPause) {
        weight *= 1.1;  // Post-pause emphasis
        }
        if (context.position > 0.8) {
        weight *= 1.1;  // Phrase-final lengthening
        }
        return weight;
    }

    private applyContractionRules(weight: number, word: string): number {
        // Contractions se tratan como unidades más cortas
        if (this.contractions.has(word) || 
            Array.from(this.contractions).some(c => word.endsWith(c))) {
        weight *= 0.8;
        }
        return weight;
    }
}

@Injectable({
  providedIn: 'root'
})
export class TextTimingService {
    private languageRules: Map<string, LanguageRules> = new Map();

    constructor() {
        // Registrar reglas para inglés
        this.languageRules.set('en', new EnglishRules());
    }

    calculateWordTimings(text: string, audioDuration: number, language: string = 'en'): WordTiming[] {
        const rules = this.languageRules.get(language);
        if (!rules) {
        throw new Error(`Language ${language} not supported`);
        }

        const words = this.tokenizeText(text);
        const totalWeight = this.calculateTotalWeight(words, rules);
        const timePerWeight = audioDuration / totalWeight;

        let currentTime = 0;
        const timings: WordTiming[] = [];

        words.forEach((word, index) => {
        const context = this.createTimingContext(word, words, index);
        const wordWeight = rules.calculateWordWeight(word, context);
        const duration = wordWeight * timePerWeight;
        const pause = this.calculatePause(word, words[index + 1], rules.getDefaultPauses());

        timings.push({
            word: word,
            start: currentTime,
            end: currentTime + duration
        });

        currentTime += duration + pause;
        });

        return this.normalizeTimings(timings, audioDuration);
    }

    private tokenizeText(text: string): string[] {
        return text.match(/\S+/g) || [];
    }

    private calculateTotalWeight(words: string[], rules: LanguageRules): number {
        return words.reduce((total, word, index) => {
        const context = this.createTimingContext(word, words, index);
        return total + rules.calculateWordWeight(word, context);
        }, 0);
    }

    private createTimingContext(word: string, words: string[], index: number): TimingContext {
        return {
        isContentWord: !word.match(/^(the|a|an|and|but|or|in|on|at|to|for)$/i),
        isEndOfSentence: this.endsWithPunctuation(word),
        isAfterPause: index > 0 && this.hasPauseBefore(words[index - 1]),
        position: index / words.length
        };
    }

    private calculatePause(currentWord: string, nextWord: string | undefined, pauseRules: PauseRules): number {
        if (!nextWord) return 0;
    
        let pause = 0;
    
        // Verificar si la siguiente palabra comienza con comillas
        if (nextWord.startsWith('"') || nextWord.startsWith("'")) {
            pause += pauseRules.quotation;
        }
    
        // Mantener la lógica existente para pausas por puntuación
        if (currentWord.endsWith('.') || currentWord.endsWith('!') || currentWord.endsWith('?')) {
            pause += pauseRules.period;
        }
        else if (currentWord.endsWith(',')) {
            pause += pauseRules.comma;
        }
        else if (currentWord.endsWith(':') || currentWord.endsWith(';') || currentWord.endsWith('...')) {
            pause += pauseRules.other;
        }
        else {
            pause += pauseRules.betweenWords;
        }
    
        return pause;
    }

    private endsWithPunctuation(word: string): boolean {
        return /[.!?]$/.test(word);
    }

    private hasPauseBefore(word: string): boolean {
        return /[,.:;!?]$/.test(word);
    }

    private normalizeTimings(timings: WordTiming[], totalDuration: number): WordTiming[] {
        const lastTiming = timings[timings.length - 1];
        const factor = totalDuration / lastTiming.end;

        return timings.map(timing => ({
        word: timing.word,
        start: timing.start * factor,
        end: timing.end * factor
        }));
    }

    // Método para registrar nuevos idiomas
    registerLanguage(language: string, rules: LanguageRules): void {
        this.languageRules.set(language, rules);
    }
}