import { DebugElement } from "@angular/core";

// statistics.type.ts
export const COMMON_WORDS_WHITELIST = new Set([
    // Articles
    'a', 'an', 'the',
    // Prepositions
    'in', 'on', 'at', 'to', 'for', 'with', 'by', 'from', 'of',
    // Conjunctions
    'and', 'or', 'but', 'nor', 'so', 'yet',
    // Other common connectors
    'if', 'when', 'while', 'because', 'as'
]);

export class StatisticsChat {

    [key: string]: number | string | undefined | (() => number);

    words_user_quantity: number = 0;
    words_error_quantity: number = 0;
    
    total_words_speaked: number = 0;
    wpm: number = 0;
    corrected_words_quantity: number = 0;

    total_seconds_speaked: number = 0;
    
    spelling_quality_percentage: number = 0;
    lexical_diversity_percentage: number = 0;

    words_assistant_quantity: number = 0;
    total_time_listening: number = 0;

    user_participation_percentage: number = 0;

    constructor(data: Partial<StatisticsChat> = {}) {
        if (data) {
            Object.assign(this, data);
        }
        
    }

    ratioParticipation() {
        if ((this.words_user_quantity + this.words_assistant_quantity) > 0) {
            return (100 * this.words_user_quantity)/(this.words_user_quantity + this.words_assistant_quantity);
        }
        
        return 0;
    }

    fluency() {
        return this.wpm >= 150 ? 100 : (this.wpm * 100 / 150);
    }
}

export class StatisticsMessage {
    words_quantity: number = 0;
    words_error_quantity: number = 0;
    wpm: number = 0;
    spelling_quality_percentage: number = 100;
    lexical_diversity_percentage: number = 0;
}


export type DailyStatisticsData = {
    [date: string]: {
        [chatId: string]: StatisticsChat
    }
};

export class UserStatistics {
    private data: DailyStatisticsData;

    constructor(data: DailyStatisticsData = {}) {
        this.data = data;
    }

    calculateAverageMetric(): StatisticsChat {
        const result = new StatisticsChat();
        let totalEntries = 0;

        Object.values(this.data).forEach(dayStats => {
            Object.values(dayStats).forEach(chatStats => {
                totalEntries++;
                Object.keys(chatStats).forEach(key => {
                    if (typeof chatStats[key] === 'number') {
                        const currentValue = result[key] as number | undefined;
                        const newValue = chatStats[key] as number;
                        result[key] = (currentValue || 0) + newValue;
                    }
                });
            });
        });

        if (totalEntries > 0) {
            Object.keys(result).forEach(key => {
                if (typeof result[key] === 'number') {
                    result[key] = Number((result[key] as number / totalEntries).toFixed(2));
                }
            });
        }

        return result;
    }
}

// Types for classroom statistics
export type ClassroomStatisticsData = {
    [userId: string]: DailyStatisticsData;
};

export class ClassroomStatistics {
    private data: ClassroomStatisticsData;

    constructor(data: ClassroomStatisticsData = {}) {
        this.data = data;
    }

    getUserStatistics(userId: string): UserStatistics {
        return new UserStatistics(this.data[userId] || {});
    }

    calculateAverageMetrics(): StatisticsChat {
        const result = new StatisticsChat();
        let totalUsers = 0;

        Object.values(this.data).forEach(userStats => {
            const userAverage = new UserStatistics(userStats).calculateAverageMetric();
            totalUsers++;

            Object.keys(userAverage).forEach(key => {
                if (typeof userAverage[key] === 'number') {
                    const currentValue = result[key] as number | undefined;
                    const newValue = userAverage[key] as number;
                    result[key] = (currentValue || 0) + newValue;
                }
            });
        });

        if (totalUsers > 0) {
            Object.keys(result).forEach(key => {
                if (typeof result[key] === 'number') {
                    result[key] = Number((result[key] as number / totalUsers).toFixed(2));
                }
            });
        }

        return result;
    }
}

// Types for school statistics
export type SchoolStatisticsData = {
    [classroomId: string]: ClassroomStatisticsData;
};

export class SchoolStatistics {
    private data: SchoolStatisticsData;

    constructor(data: SchoolStatisticsData = {}) {
        this.data = data;
    }

    getClassroomStatistics(classroomId: string): ClassroomStatistics {
        return new ClassroomStatistics(this.data[classroomId] || {});
    }

    calculateAverageMetrics(): StatisticsChat {
        const result = new StatisticsChat();
        let totalClassrooms = 0;

        Object.values(this.data).forEach(classroomStats => {
            const classroomAverage = new ClassroomStatistics(classroomStats).calculateAverageMetrics();
            totalClassrooms++;

            Object.keys(classroomAverage).forEach(key => {
                if (typeof classroomAverage[key] === 'number') {
                    const currentValue = result[key] as number | undefined;
                    const newValue = classroomAverage[key] as number;
                    result[key] = (currentValue || 0) + newValue;
                }
            });
        });

        if (totalClassrooms > 0) {
            Object.keys(result).forEach(key => {
                if (typeof result[key] === 'number') {
                    result[key] = Number((result[key] as number / totalClassrooms).toFixed(2));
                }
            });
        }

        return result;
    }
}
